import moment from 'moment';
import React, { Component } from 'react';
import {
  Alert,
  Button,
  Grid,
  Row,
  Table,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import Select from 'react-select';
import LoaderButton from '../components/LoaderButton';
import RouteStop from '../components/PackingSlips/RouteStop';
import { US_STATES, US_STATES_CUSTOM_CODES } from '../constants/USStates';
import {
  GetOpsLocations,
  Items,
  LocationIDs,
  PackingSlipPreview,
  PaginatedPackingSlip,
  GetFulfillmentPriority,
} from '../libs/GraphQL';
import { yyyymmdd } from '../libs/date_convert';
import { generateLayout } from '../libs/generateLayout';
import Loading from '../atoms/Loading';
import { mockOpsLocations, mockItems, mockLocations } from '../utils/SLIP_DATA';
import { returnedSlips } from '../utils/SLIP_RESPONSE';

const arrayToObject = (array, keyField) =>
  array.reduce((obj, item) => {
    obj[item[keyField]] = item;
    return obj;
  }, {});

export default class ViewProdReport extends Component {
  constructor(props) {
    super(props);
    this.file = null;
    this.state = {
      previewSlips:
        window.location.pathname.split('/').slice(-1)[0] === 'preview',
      title:
        window.location.pathname.split('/').slice(-1)[0] === 'preview'
          ? 'Preview'
          : 'Allocation',
      isInitLoading: true,
      isLoading: false,
      showPicker: true,
      reportData: {},
      items: {},
      fridgeLocationIds: [],
      fridgeLocationState: {},
      currentDate: new Date(),
      searchDate: yyyymmdd(new Date(), '-'),
      packingSlipType: 'all',
      packingSlipPreviewType: 'all-orders',
      packingSlipGroup: 'all-groups',
      alertModal: {
        mode: false,
        text: '',
      },
      isACostcoStore: {},
      containerTypes: {},
      opsLocations: {},
      linehaulList: [],
      linehaulFilter: [],
      linehaulFilterType: 'all',
    };
    this.refreshData = this.refreshData.bind(this);
    this.handleDateChangeV2 = this.handleDateChangeV2.bind(this);
    this.showDatePicker = this.showDatePicker.bind(this);
    this.getForecastData = this.getForecastData.bind(this);
    this.downloadPackingSlips = this.downloadPackingSlips.bind(this);
    this.getFridgeLocationIds = this.getFridgeLocationIds.bind(this);
    this.getFridgeLocationState = this.getFridgeLocationState.bind(this);
    this.getFridgeRegion = this.getFridgeRegion.bind(this);
    this.getOrderRegion = this.getOrderRegion.bind(this);
    this.sundryCount = this.sundryCount.bind(this);
    this.shouldDisplaySundry = this.shouldDisplaySundry.bind(this);
    this.fetchAllPackingSlips = this.fetchAllPackingSlips.bind(this);
    this.handleLinehaulFilterChange =
      this.handleLinehaulFilterChange.bind(this);
  }

  refreshData() {
    this.setState({ isLoading: true });
    this.getForecastData();
  }

  async componentDidMount() {
    try {
      const itemsAndLocationsCache = JSON.parse(
        localStorage.getItem('itemsAndLocationsCache'),
      );
      if (
        itemsAndLocationsCache &&
        itemsAndLocationsCache.date &&
        moment(itemsAndLocationsCache.date).isSame(moment(), 'day')
      ) {
        this.setState({
          items: itemsAndLocationsCache.items,
          opsLocations: itemsAndLocationsCache.opsLocations,
          isACostcoStore: itemsAndLocationsCache.isACostcoStore,
          containerTypes: itemsAndLocationsCache.containerTypes,
          fridgeLocationIds: itemsAndLocationsCache.fridgeLocationIds,
          fridgeLocationState: itemsAndLocationsCache.fridgeLocationState,
          linehaulList: itemsAndLocationsCache.linehaulList,
          isInitLoading: false,
        });
      } else {
        // Initiate all requests simultaneously
        const [itemsResponse, allLocationsResponse, opsLocationsResponse] =
          await Promise.all([Items(), LocationIDs(), GetOpsLocations()]);

        // Mock Data added to improve dev speed
        // TODO for commits:
        // Comment out the below lines and uncomment the above lines
        // const itemsResponse = mockItems;
        // const allLocationsResponse = mockLocations;
        // const opsLocationsResponse = mockOpsLocations;

        // Process items data
        const items = itemsResponse.data.items;
        this.setState({ items: arrayToObject(items, 'id') });

        // Process opsLocations data
        const opsLocations = opsLocationsResponse.data.opsLocations.locations;
        const opsLocationsData = arrayToObject(opsLocations, 'id');

        const linehaulsUniqueValues = opsLocations
          .map((item) => item.linehaulFirstMile)
          .filter((value) => value !== null);
        const linehauls = [...new Set(linehaulsUniqueValues)];
        let formattedLinehaul = linehauls.map((linehaul) => ({
          value: linehaul,
        }));
        formattedLinehaul = formattedLinehaul.sort((a, b) =>
          a.value.localeCompare(b.value),
        );
        formattedLinehaul = formattedLinehaul.filter(
          (item) => item.value !== 'Does Not Exist',
        );

        const containerTypes = opsLocations.reduce((obj, location) => {
          const hasContainerType = location.containerType
            ? location.containerType
            : 'Box';
          return {
            ...obj,
            [location.id]: hasContainerType,
          };
        }, {});

        const isACostcoStore = opsLocations.reduce((obj, location) => {
          const isCostco = location?.opportunityName?.includes('Costco');
          return {
            ...obj,
            [location.id]: isCostco,
          };
        }, {});

        // Process allLocations data
        const allLocations = allLocationsResponse;
        const fridgeLocationIds = this.getFridgeLocationIds(allLocations);
        const fridgeLocationState = this.getFridgeLocationState(allLocations);

        // Set the state with all the processed data
        const curState = {
          items,
          isACostcoStore,
          fridgeLocationIds,
          fridgeLocationState,
          containerTypes,
          opsLocations: opsLocationsData,
          linehaulList: formattedLinehaul,
          isInitLoading: false,
        };
        this.setState(curState);
        curState.date = moment().format('YYYY-MM-DD');
        localStorage.setItem(
          'itemsAndLocationsCache',
          JSON.stringify(curState),
        );
      }
    } catch (error) {
      console.error(error);
      this.setState({ isInitLoading: false });
    }
  }
  async fetchAllPackingSlips(date) {
    const items = { data: { packingSlips: [] } };
    let nextToken = null;

    try {
      do {
        const response = await PaginatedPackingSlip(date, nextToken);
        if (response?.data?.paginatedPackingSlips?.packingSlips) {
          const packingSlips = response.data.paginatedPackingSlips.packingSlips;
          items.data.packingSlips.push(...packingSlips);
          nextToken = response.data.paginatedPackingSlips.nextToken;
        }
      } while (nextToken);
    } catch (error) {
      console.error(`Error fetching data for ${date}:`, error);
    }
    return items;
  }

  async downloadPackingSlips() {
    let packingSlips;
    // Based on user selection this will either download preview or actual packingslips
    try {
      if (this.state.previewSlips) {
        packingSlips = PackingSlipPreview(this.state.searchDate);
      } else {
        return await this.fetchAllPackingSlips(this.state.searchDate);
        // Comment out above and uncomment below to use mock data
        // packingSlips = returnedSlips;
      }
      return packingSlips;
    } catch (error) {
      console.error(error);
      this.setState({
        alertModal: {
          mode: true,
          text: 'Failed to download Packing Slips',
        },
      });
    }
  }

  getFridgeLocationIds(allLocations) {
    return allLocations.map((location) => location.id);
  }

  getFridgeLocationState(allLocations) {
    const locationAddresses = {};
    allLocations.map(
      (location) =>
        (locationAddresses[location.id] = this.getFridgeRegion(
          location.operationConfigs.address,
        )),
    );
    return locationAddresses;
  }

  cleanPackingSlips(packingSlips, items, opsLocations, fulfillmentPriority) {
    let pSlips;
    if (packingSlips.hasOwnProperty('data')) {
      if (packingSlips.data.hasOwnProperty('packingSlips')) {
        pSlips = packingSlips.data.packingSlips;
      } else if (packingSlips.data.hasOwnProperty('packingSlipsPreview')) {
        pSlips = packingSlips.data.packingSlipsPreview;
      }
    }
    const result = [];
    for (const key in pSlips) {
      const tempData = pSlips[key].data;
      const tempDataNew = {};
      for (const d in tempData) {
        if (tempData[d].itemName === 'DTC_TIP' || tempData[d].value === 0) {
          continue;
        }

        tempDataNew[tempData[d].itemName] = {
          value: tempData[d].value,
          productionDate: tempData[d].productionDate,
          unitsPerPack: items[tempData[d].itemName]?.unitsPerPack,
        };
      }
      pSlips[key].data_ids = tempDataNew;

      const locationId = pSlips[key].location.split('&')[0].trim();

      // Add data from opsLocations
      const opsLocation = this.state.opsLocations[locationId];
      if (opsLocation) {
        pSlips[key].locationType = opsLocation.type;
        pSlips[key].sundriesMultiplier = opsLocation.sundriesMultiplier;
        pSlips[key].linehaulFirstMile = opsLocation.linehaulFirstMile;
        pSlips[key].linehaulMiddleMile = opsLocation.linehaulMiddleMile;

        // pSlips[key].market = opsLocation.market; TODO: Add `market` to packing slips when ID-4590 will be merged
      }

      delete pSlips[key].data;

      // Add priority to each packing slip
      const matchingObject = fulfillmentPriority?.find((item) =>
        item.locations.includes(locationId),
      );

      if (matchingObject) {
        pSlips[key].fulfillmentPriority = matchingObject.priority || 0;
      } else {
        console.log(
          `Location ID "${locationId}" not found in the fulfillmentPriority array.`,
        );
      }

      // Add fulfillmentCenter to each packing slip
      pSlips[key].fulfillmentCenter =
        opsLocations[locationId]?.fulfillmentCenter || 'UNKNOWN';

      result.push(pSlips[key]);
    }
    return pSlips;
  }

  shouldDisplaySundry = (location, routeStop) => {
    // const isFridge = routeStop?.locationType === 'FRIDGE';
    // const isDetroit = location.startsWith('MI_');
    // return isFridge && isDetroit;
    return false;
  };

  sundryCount = (location, data, routeStop, sundryMultiplier) => {
    let spoonsCount = 0;
    let forksCount = 0;
    const cleanLocation = location.split('&')[0].trim();
    if (this.shouldDisplaySundry(cleanLocation, routeStop)) {
      Object.entries(data).forEach(([key, fields]) => {
        const item = this.state.items.find((item) => item.id === key);
        if (item?.sundryType === 'Fork') {
          forksCount += fields.value;
        }
        if (item?.sundryType === 'Spoon') {
          spoonsCount += fields.value;
        }
      });

      forksCount = Math.ceil(forksCount * sundryMultiplier);
      spoonsCount = Math.ceil(spoonsCount * sundryMultiplier);
      return {
        forks: forksCount,
        spoons: spoonsCount,
      };
    }
  };

  handleLinehaulFilterChange(value) {
    this.setState({
      linehaulFilter: value,
    });
  }

  async getForecastData() {
    try {
      this.setState({
        isLoading: true,
      });
      const packingSlips = await this.downloadPackingSlips();
      if ('errors' in packingSlips) {
        const alertModal = this.state.alertModal;
        alertModal.mode = true;
        alertModal.text = packingSlips.errors[0].message;
        this.setState({ alertModal });
      } else {
        let packingSlipsLocationsIDs = [];
        if (this.state.previewSlips) {
          packingSlipsLocationsIDs = packingSlips.data.packingSlipsPreview.map(
            (item) => {
              return item.location.split('&')[0].trim();
            },
          );
        } else {
          const getLocationIds = (item) => {
            return item.location.split('&')[0].trim();
          };
          packingSlipsLocationsIDs =
            packingSlips.data.packingSlips.map(getLocationIds);
        }

        const uniquePackingSlipsList = [...new Set(packingSlipsLocationsIDs)];
        const fulfillmentPriority = await GetFulfillmentPriority({
          locations: uniquePackingSlipsList,
        });

        this.setState({
          isLoading: false,
          reportData: this.cleanPackingSlips(
            packingSlips,
            this.state.items,
            this.state.opsLocations,
            fulfillmentPriority.data.getFulfillmentPriority,
          ),
          showPicker: false,
        });
      }
    } catch (e) {
      console.error(e);
      const alertModal = this.state.alertModal;
      alertModal.mode = true;
      alertModal.text =
        'Unable to load packing slips, Please check your internet and permissions';
      this.setState({ alertModal });
    }
  }

  handleDateChangeV2(value) {
    this.setState({
      searchDate: yyyymmdd(value, '-'),
      currentDate: value,
    });
  }

  showDatePicker() {
    this.setState({
      showPicker: !this.state.showPicker,
    });
  }

  getSortedData(data_array, summary) {
    // default multiplier for sundries, otherwise use the multiplier from the operations record
    const sundryMultiplier = 1.5;
    const groupedData = {};

    // Grouping by driver (route) and dropOffDate
    for (const i in data_array) {
      const location = data_array[i].location.split('&')[0].trim();

      // Marking items that are Costco stores
      if (
        this.state.isACostcoStore &&
        this.state.isACostcoStore[location] === true
      ) {
        data_array[i].costco = true;
      }

      // Assigning container type and updating summary data
      if (this.state.containerTypes) {
        data_array[i].containerType = this.state.containerTypes[location];
        if (!summary.data_ids[data_array[i].containerType]) {
          summary.data_ids[data_array[i].containerType] = { value: 0 };
        }
        summary.data_ids[data_array[i].containerType].value += 1;
      }

      // Grouping by driver and dropOffDate
      const driver = data_array[i].driver;
      const dropOffDate =
        data_array[i].dropOffDate == null ? 1 : data_array[i].dropOffDate;
      const groupKey = `${driver}-${dropOffDate}`;

      if (groupKey in groupedData) {
        groupedData[groupKey].push(data_array[i]);
      } else {
        groupedData[groupKey] = [data_array[i]];
      }
    }

    // Remove undefined container types from summary data
    delete summary.data_ids.undefined;
    groupedData.null = [summary];
    const sundryMap = {};
    // Sorting each group by various criteria in one sort function
    for (const j in groupedData) {
      // Sort by subPackingSlipIndex
      groupedData[j].sort((a, b) =>
        a.subPackingSlipIndex === b.subPackingSlipIndex
          ? 0
          : a.subPackingSlipIndex > b.subPackingSlipIndex
          ? 1
          : -1,
      );
      // Sort by combined driver-stop-location key
      groupedData[j].sort((a, b) =>
        `${a.driver} - ${a.stop}-${a.location.split('&')[0]}` >
        `${b.driver} - ${b.stop}-${b.location.split('&')[0]}`
          ? 1
          : -1,
      );
      groupedData[j].forEach((slip) => {
        if (!sundryMap[slip.location]) {
          const sundryData = this.sundryCount(
            slip.location,
            slip.data_ids,
            slip,
            slip.sundriesMultiplier || sundryMultiplier,
          );
          sundryMap[slip.location] = sundryData;
        } else {
          const sundryData = this.sundryCount(
            slip.location,
            slip.data_ids,
            slip,
            slip.sundriesMultiplier || sundryMultiplier,
          );
          sundryMap[slip.location].forks += sundryData.forks;
          sundryMap[slip.location].spoons += sundryData.spoons;
        }
      });
      groupedData[j].forEach((slip, i) => {
        if (sundryMap[slip.location]) {
          const temp = {
            ...slip,
            forks: sundryMap[slip.location].forks,
            spoons: sundryMap[slip.location].spoons,
          };
          if (slip.subPackingSlipIndex === slip.totalSubPackingSlips) {
            groupedData[j][i] = temp;
          }
        }
      });
    }

    // Sorting keys by fulfillmentPriority
    const sortedKeys = Object.keys(groupedData).sort((a, b) => {
      const aPriority = groupedData[a][0].fulfillmentPriority;
      const bPriority = groupedData[b][0].fulfillmentPriority;

      // First, sort by fulfillmentPriority
      if (aPriority !== bPriority) {
        return aPriority - bPriority; // Ascending order
      }

      // If priorities are the same, sort by date in descending order
      const aDate = groupedData[a][0].dropOffDate || '1970-01-01';
      const bDate = groupedData[b][0].dropOffDate || '1970-01-01';
      return new Date(bDate) - new Date(aDate); // Descending order
    });

    // Creating orderedGroupedData as a Map to preserve order
    let orderedGroupedData = new Map();
    const unrouted = new Map();
    const internals = new Map();
    sortedKeys.forEach((key) => {
      if (key.includes('Route data unavailable')) {
        unrouted.set(key, groupedData[key]);
      } else if (key.includes('Internal') || key.includes('NOT_NEEDED')) {
        internals.set(key, groupedData[key]);
      } else {
        orderedGroupedData.set(key, groupedData[key]);
      }
    });
    orderedGroupedData = new Map([
      ...internals,
      ...unrouted,
      ...orderedGroupedData,
    ]);
    return orderedGroupedData;
  }

  getSortedStopSummary(orderedGroupedData) {
    const stops = new Map();

    orderedGroupedData.forEach((orders, key) => {
      orders.forEach((order) => {
        if (order.clientCode === 'ThirdParty') {
          return;
        }
        const dropOffDate = order.dropOffDate == null ? '' : order.dropOffDate;
        const stopKey = order.location.split('&')[0] + order.date + dropOffDate;

        stops.set(
          stopKey,
          <tr key={stopKey}>
            <td>
              {order.driver} - {order.stop}
            </td>
            <td>{order.location.split('&')[0]}</td>
            <td>
              <div>
                {order.dropOffDate
                  ? moment(order.dropOffDate).format('dddd MMMM Do YYYY')
                  : 'Drop off date not specified'}
              </div>
            </td>
          </tr>,
        );
      });
    });

    return Array.from(stops.values());
  }

  filterFridgeOrders(route_stop) {
    const indexOfValue = this.state.fridgeLocationIds.findIndex(
      (i) => i === route_stop.addressForDelivery,
    );
    return (
      route_stop.type === 'ORDER' &&
      !route_stop.addressForDelivery.includes('SHOPIFY_') &&
      indexOfValue > -1
    );
  }

  filterCateringOrders(route_stop) {
    const indexOfValue = this.state.fridgeLocationIds.findIndex(
      (i) => i === route_stop.addressForDelivery,
    );
    return (
      (route_stop.type === 'ORDER' &&
        !route_stop.addressForDelivery.includes('SHOPIFY_') &&
        indexOfValue <= -1) ||
      (route_stop.type === 'ORDER' &&
        route_stop.addressForDelivery.includes('SHOPIFY_') &&
        indexOfValue <= -1 &&
        (route_stop.clientCode === 'Wholesale' ||
          route_stop.clientCode === 'Catering')) ||
      route_stop.type === 'INTERNAL_ORDER'
    );
  }

  filterLabelOrders(route_stop) {
    return (
      route_stop.type === 'ORDER' &&
      (route_stop.location.includes('COSTCO_') ||
        route_stop.location.includes('IL_HMS_OHARE__1000_BALMORAL_AVE'))
    );
  }

  getFridgeRegion(fridgeAddress) {
    let address = fridgeAddress;
    const statesCodes = Object.keys(US_STATES);
    const statesCustomCodes = Object.keys(US_STATES_CUSTOM_CODES);
    // remove all commas
    address = address.replace(/,/g, '');
    // remove unnecessary spaces in between
    address = address.replace(/\s+/g, ' ');
    // split address string into words
    const partsOfAddress = address.trim().split(' ');
    for (let i = 0; i < partsOfAddress.length; i++) {
      if (statesCodes.includes(partsOfAddress[i])) {
        return partsOfAddress[i];
      }
      if (statesCustomCodes.includes(partsOfAddress[i])) {
        const correctStateCode = US_STATES_CUSTOM_CODES[partsOfAddress[i]];
        return US_STATES[correctStateCode];
      }
    }
    return 'UNKNOWN_REGION';
  }

  getOrderRegion(location) {
    const currentLocation = location.split('&')[0];
    const prefix = currentLocation.split('_')[0];
    const listOfFridgeLocationState = this.state.fridgeLocationState;

    if (prefix in US_STATES) {
      return prefix;
    }

    if (prefix in US_STATES_CUSTOM_CODES) {
      return US_STATES_CUSTOM_CODES[prefix];
    }

    if (currentLocation in listOfFridgeLocationState) {
      return listOfFridgeLocationState[currentLocation];
    }

    return 'UNKNOWN_REGION';
  }

  render() {
    const {
      reportData,
      searchDate,
      previewSlips,
      packingSlipPreviewType,
      packingSlipType,
      linehaulFilter,
      linehaulFilterType,
    } = this.state;

    const currentlyDisplaying = `Currently Displaying: ${searchDate}`;

    let packingSlipText = '';
    let filtersApplied = '';

    if (previewSlips) {
      packingSlipText = packingSlipPreviewType.toUpperCase().replace(/-/g, ' ');
    } else {
      packingSlipText = packingSlipType.toUpperCase().replace(/-/g, ' ');
    }

    const linehaulText =
      linehaulFilterType !== 'all'
        ? linehaulFilter?.map((item) => item.value).join(', ')
        : 'ALL LINEHAUL';
    filtersApplied = `${packingSlipText} & ${linehaulText}`;

    let keys = [];
    let data_array = {};
    const sums = {
      location: 'Summary',
      data_ids: {},
    };

    const LAYOUT = generateLayout(this.state.items);

    if (Array.isArray(reportData)) {
      const renderData = reportData.filter((route_stop) => {
        const isOrderType = route_stop.type === 'ORDER';
        const isInternalOrderType = route_stop.type === 'INTERNAL_ORDER';
        const isAllocationType = route_stop.type === 'ALLOCATION';
        const isCostco = route_stop.location.includes('COSTCO_');
        const isHMS = route_stop.location.includes(
          'IL_HMS_OHARE__1000_BALMORAL_AVE',
        );

        // Linehaul filtering logic with added check for linehaulFilter array
        const linehaulFirstMile = route_stop.linehaulFirstMile;
        const linehaulFilterValues = Array.isArray(this.state.linehaulFilter)
          ? this.state.linehaulFilter.map((filter) => filter.value)
          : [];

        // Skip linehaul filtering if linehaulFilterType is "all"
        const passesLinehaulFilter =
          linehaulFilterType === 'all' ||
          (linehaulFirstMile &&
            linehaulFilterValues.includes(linehaulFirstMile)) ||
          (route_stop.location === 'INTERNAL' &&
            linehaulFilterValues.includes('IL-CHI'));

        if (!passesLinehaulFilter) return false;

        // Apply preview and packingSlipType filters
        if (previewSlips) {
          if (packingSlipPreviewType === 'all-orders') {
            return isOrderType || isInternalOrderType;
          } else if (packingSlipPreviewType === 'fridge-orders') {
            return this.filterFridgeOrders(route_stop);
          } else if (packingSlipPreviewType === 'non-fridge-orders') {
            return this.filterCateringOrders(route_stop);
          } else if (packingSlipPreviewType === 'label-orders') {
            return this.filterLabelOrders(route_stop);
          }
        } else if (packingSlipType === 'all') {
          return !isCostco && !isHMS;
        } else if (packingSlipType === 'order') {
          return (isOrderType || isInternalOrderType) && !isHMS && !isCostco;
        } else if (packingSlipType === 'allocation') {
          return isAllocationType && !isHMS && !isCostco;
        } else if (packingSlipType === 'label-orders') {
          return this.filterLabelOrders(route_stop);
        }

        return false;
      });

      keys = Object.keys(renderData);
      data_array = renderData;
    }

    keys.forEach((key) => {
      const skus = Object.keys(data_array[key].data_ids);

      // Iterate over the skus
      skus.forEach((sku) => {
        const valueToAdd = parseInt(
          data_array[key].data_ids[sku]?.value || 0,
          10,
        );

        if (valueToAdd === 0) {
          return;
        }

        // Initialize sums.data_ids[sku] if it doesn't exist
        if (!sums.data_ids[sku]) {
          sums.data_ids[sku] = { value: 0 };
        }

        // Add valueToAdd to sums.data_ids[sku].value
        sums.data_ids[sku].value += valueToAdd;
      });
    });

    let stops = [];
    let rows = [];
    if (Object.keys(data_array).length > 0) {
      const orderedGroupedData = this.getSortedData(data_array, sums);
      stops = this.getSortedStopSummary(orderedGroupedData);
      orderedGroupedData.forEach((routeStops) => {
        rows.push(
          routeStops.map((route_stop) => {
            if (route_stop.clientCode !== 'ThirdParty') {
              return (
                <RouteStop
                  routeStop={route_stop}
                  layout={LAYOUT}
                  items={this.state.items}
                  fridgeLocationState={this.state.fridgeLocationState}
                  orderRegion={this.getOrderRegion(route_stop.location)}
                />
              );
            }
            return null;
          }),
        );
      });
    } else {
      const today = moment().startOf('day');
      const currentDateMoment = moment(this.state.currentDate).startOf('day');

      rows = (
        <div>
          <Alert bsStyle="danger">
            <h3>No Packing Slips found for the selected date and filters</h3>
          </Alert>
          <br />
          {currentDateMoment.isSameOrAfter(today) && (
            <Alert bsStyle="info">
              <h3>
                Packing slips are not created until 6pm on the day of. If you
                would like to view a preview of PackingSlips created from
                orders, please view them on the{' '}
                <a href="https://facility.farmerfridge.com/reports/allocation/preview">
                  Packing Slip Preview Page
                </a>
                .
              </h3>
            </Alert>
          )}
        </div>
      );
    }

    return (
      <div>
        <h1>{this.state.title} Packing Slips</h1>

        {!this.state.showPicker && (
          <div>
            <h2>{currentlyDisplaying}</h2>
            <h3>Filters Applied: {filtersApplied}</h3>
            <LoaderButton
              data-test="Loader-Button"
              block
              bsStyle="primary"
              bsSize="large"
              onClick={this.showDatePicker}
              type="submit"
              isLoading={Object.keys(this.state.containerTypes).length === 0}
              text="Click to choose Another Date"
              loadingText={`Loading Data for: ${this.state.searchDate}`}
            />
          </div>
        )}
        {this.state.showPicker && (
          <Grid style={{ minHeight: '80vh' }}>
            <Row className="text-center" style={{ marginBottom: '24px' }}>
              <div className="inputLabel">Production Date</div>
              <DatePicker
                data-test="Date-Picker"
                onChange={this.handleDateChangeV2}
                value={this.state.currentDate}
              />
            </Row>

            {!this.state.previewSlips ? (
              <>
                <div className="inputWrapper">
                  <ToggleButtonGroup
                    type="radio"
                    name="options"
                    value={this.state.packingSlipType}
                    onChange={(e) => {
                      this.setState({
                        packingSlipType: e,
                      });
                    }}
                  >
                    <ToggleButton value="all">ALL Packing Slips</ToggleButton>
                    <ToggleButton value="order">Orders</ToggleButton>
                    <ToggleButton value="allocation">Allocation</ToggleButton>
                    <ToggleButton value="label-orders">
                      Label Orders
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </>
            ) : (
              <Row style={{ margin: '0px 0px 24px 0px' }}>
                <ToggleButtonGroup
                  type="radio"
                  name="options"
                  value={this.state.packingSlipPreviewType}
                  onChange={(e) => {
                    this.setState({
                      packingSlipPreviewType: e,
                    });
                  }}
                >
                  <ToggleButton value="all-orders">All Orders</ToggleButton>
                  <ToggleButton value="fridge-orders">
                    Fridge Orders
                  </ToggleButton>
                  <ToggleButton value="non-fridge-orders">
                    Non-Fridge Orders
                  </ToggleButton>
                  <ToggleButton value="label-orders">Label Orders</ToggleButton>
                </ToggleButtonGroup>
              </Row>
            )}
            <div className="inputWrapper">
              <div>
                <ToggleButtonGroup
                  type="radio"
                  name="options"
                  value={this.state.linehaulFilterType}
                  onChange={(e) => {
                    this.setState({
                      linehaulFilterType: e,
                    });
                  }}
                >
                  <ToggleButton value="all">ALL Linehaul</ToggleButton>
                  <ToggleButton value="select">Select Linehaul</ToggleButton>
                </ToggleButtonGroup>
              </div>
              {this.state.linehaulFilterType !== 'all' && (
                <>
                  <Select
                    options={this.state.linehaulList}
                    getOptionLabel={(option) => option.value}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={this.handleLinehaulFilterChange}
                    isLoading={this.state.isLoading || this.state.isInitLoading}
                    isDisabled={
                      this.state.isLoading || this.state.isInitLoading
                    }
                    value={
                      this.state.linehaulFilter?.length
                        ? this.state.linehaulFilter
                        : null
                    }
                    maxMenuHeight={400}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderRadius: '0px',
                        border: '1px solid gray',
                        width: '380px',
                      }),
                    }}
                  />
                </>
              )}
            </div>
            <p />
            <Row className="text-center">
              {!this.state.isInitLoading ? (
                <Button
                  bsStyle="primary"
                  bsSize="large"
                  onClick={this.getForecastData}
                  data-testid="Search Button"
                  disabled={
                    this.state.isLoading ||
                    (this.state.linehaulFilterType === 'select' &&
                      !this.state.linehaulFilter?.length)
                  }
                >
                  {this.state.isLoading ? 'Loading...' : 'Search'}
                </Button>
              ) : (
                <>
                  <Loading />
                  <span>Initial Data Fetching...</span>
                </>
              )}
            </Row>
          </Grid>
        )}
        <hr />
        <p style={{ pageBreakBefore: 'always' }} />
        <p />
        <div key="data" style={{ fontSize: 'large' }}>
          {!this.state.isLoading &&
            !this.state.showPicker &&
            Object.keys(this.state.containerTypes).length > 0 && (
              <Table data-test="stops-table">
                <tbody>{stops}</tbody>
              </Table>
            )}
          <p style={{ pageBreakBefore: 'always' }} />
          {!this.state.isLoading &&
            !this.state.showPicker &&
            Object.keys(this.state.containerTypes).length > 0 &&
            rows}
        </div>
      </div>
    );
  }
}
